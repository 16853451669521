<template>
  <section class="choose-selectedPlan-router">
    <div class="header" v-auth="'list_ss'">
      <div class="title">数据筛选</div>
      <div class="header-container">
        <div class="text">年度：</div>
        <el-date-picker v-model="table.query.jhksnd" type="year" size="small" placeholder="请选择年度"></el-date-picker>
        <el-button type="primary" class="search" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="main">
      <div class="main-header">
        <div class="router2-name">选任计划列表</div>
      </div>
      <div class="table">
        <el-table
          :data="table.data"
          row-key="id"
          height="100%"
          v-loading="table.loading"
          :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" ></el-table-column>
          <el-table-column prop="jhksnd" label="年度" width="80"></el-table-column>
          <el-table-column prop="jhmc" label="选任标题"></el-table-column>
          <el-table-column prop="org.name" label="选任单位"></el-table-column>
          <el-table-column prop="jhxrrs" label="计划总人数"></el-table-column>
          <el-table-column prop="sjcxrs" label="随机抽选人数"></el-table-column>
          <el-table-column prop="bmrs" label="个人申请/组织推荐人数"></el-table-column>
          <el-table-column prop="bmkssj" label="报名开始时间" width="160"></el-table-column>
          <el-table-column prop="bmjssj" label="报名截止时间" width="160"></el-table-column>
          <el-table-column prop="name" label="操作" width="226px">
            <template slot-scope="scope">
              <el-button
                @click="seeFile(scope.row)"
                type="text"
                size="small"
                v-if="scope.row.attach && scope.row.attach.length > 0"
              >查看附件</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
import SelectPlan from '@/api/choose/selectPlan';
import Public from '@/api/public';
// import getXRDW from '@/plugin/getXRDW';
export default {
  // 选任计划
  name: 'selectedPlan',
  props: {},
  components: {},
  data() {
    return {
      table: {
        query: {
          jhksnd: new Date().getFullYear().toString(),

          size: 10,
          page: 1,
        },
        data: [],
        options: [],
        total: 0,
        loading: false,
      },
      componentName: '',
      title: '',
      OrgTree: [],
    };
  },
  methods: {
    seeFile(data) {
      let url = `http://jxssft.rmpsy.jxtongke.com/api/${data.attach[0].path}`;
      let a = document.createElement('a');
      a.href = url;
      a.target = '_black';
      this.$nextTick(() => {
        a.click();
        a = undefined;
      });
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    search() {
      return this.getList();
    },
    reset() {
      let jhksnd = new Date().getFullYear().toString();
      let { jhlx } = this.table.query;
      this.table.query = {
        jhksnd,
        size: 10,
        page: 1,
        jhlx,
      };
      return this.getList();
    },
    // 获取选任计划列表
    async getList() {
      this.table.loading = true;
      SelectPlan.list_ss(this.table.query)
        .then((res) => {
          // let data = getXRDW(res.items, 'xrdw', this.OrgTree);
          // console.log(data);
          this.table.data = res.items;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  async created() {
    Public.components({
      OrgTree: 0,
      OrgGradeTree: 0,
    }).then((res) => {
      console.log(res);
      this.OrgTree = res.OrgTree;
    });
    return this.getList();
  },
  computed: {},
  watch: {
    componentName(newvalue) {
      switch (newvalue) {
        case 'checkCode': {
          this.title = '查看二维码';
          break;
        }
      }
    },
    '$store.getters.active_top_nav': {
      handler: function (n) {
        switch (n.meta.title) {
          case '选任': {
            this.table.query.jhlx = '1';
            break;
          }
          case '增补': {
            this.table.query.jhlx = '2';
            break;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.choose-selectedPlan-router {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .header {
    background-color: #fff;
    margin-bottom: 20px;
    .title {
      color: $h4;
      border-bottom: 1px solid $border1;
      padding: 15px 30px;
      font-weight: bold;
    }
    .header-container {
      padding: 15px 30px;
      display: flex;
      align-items: center;
      .search {
        margin-left: 20px;
      }
      .text {
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .main {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .router2-name {
        color: $h4;
        padding: 15px 0;
        font-weight: bold;
      }
      .el-button {
        margin-right: 20px;
      }
    }

    .table {
      flex: 1;
      .el-table {
        width: calc(100% - 20px);
        border: 1px solid rgba(232, 232, 232, 1);
        border-bottom: none;
        .header-bgc {
          background-color: $border4;
        }
        .operate {
          width: 100%;
          display: flex;
        }
      }
    }
    .el-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
