import { tongke } from '@/axios'
class SelectPlan {
  /**
   * @functionName list
   * @param { Object }  params
   * @param { Object }  params.page
   * @param { Object }  params.size
   * @param { Object }  params.jhlx //计划类型
   * @param { Object }  params.jhksnd //计划开始年度
   * @Description   // 选任计划列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/quxian/xrjh', { params })
  }
  list_ss(params) {
    return tongke.get('/shengshi/xrjh', { params })
  }
  // 选任详情
  detail(id) {
    return tongke.get(`/quxian/xrjh/${id}`)
  }

  /**
   * @functionName create
   * @param { Object }
   * @param { String }  params.jhmc 计划名称
   * @param { String }  params.jhksnd 计划开始年度，格式：YYYY
   * @param { Number }  params.sjcxrs   随机抽选人数
   * @param { String }  params.bmrs 报名人数
   * @param { String }  params.bmkssj 报名开始时间，格式：YYYY-MM-DD
   * @param { String }  params.bmjssj 报名结束时间，格式：YYYY-MM-DD
   * @param { String }  params.jhlx 计划类型，0-选任|1-增补
   * @param { Array }  params.attach  附件
   * @param { String }  params.attach.name 附件名称
   * @param { String }  params.attach.path  附件地址
   * @Description
   * @return { Object }
   */
  // 新建选任计划
  create(params) {
    return tongke.post(`/quxian/xrjh`, params)
  }

  /**
   * @functionName update
   * @param { Object }
   * @param { Number }  params.id 计划id
   * @param { String }  params.jhmc 计划名称
   * @param { String }  params.jhssdw 计划所属单位
   * @param { String }  params.jhksnd 计划开始年度，格式：YYYY
   * @param { Number }  params.sjcxrs   随机抽选人数
   * @param { String }  params.bmrs 报名人数
   * @param { String }  params.bmkssj 报名开始时间，格式：YYYY-MM-DD
   * @param { String }  params.bmjssj 报名结束时间，格式：YYYY-MM-DD
   * @param { String }  params.jhlx 计划类型，0-选任|1-增补
   * @param { Array }  params.attach  附件
   * @param { String }  params.attach.name 附件名称
   * @param { String }  params.attach.path  附件地址
   * @Description
   * @return { Object }
   */
  // 修改选任计划
  update(params) {
    return tongke.put(`/quxian/xrjh/${params.id}`, params)
  }

  // 上传excel表格
  loadExcel(params) {
    return tongke.post('/import/check', params)
  }

  // 上传pdf
  loadPDF(params) {
    return tongke.post('/upload/attach', params)
  }
}
export default new SelectPlan()
